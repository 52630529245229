<div class="main-container b">
  <div class="menu" [class.selected-menu]="is_show_container_A01" (click)="showItem('A01')">
    <img class="menu-image" src="https://home345.singprasertgroup.com/public/home345_containers/A01/cover.jpg" alt="">
    <div style="text-align: center; margin-top: 5px;">A01</div>
  </div>
  <div class="menu" [class.selected-menu]="is_show_container_A02" (click)="showItem('A02')">
    <img class="menu-image" src="https://home345.singprasertgroup.com/public/home345_containers/A02/cover.jpg" alt="">
    <div style="text-align: center; margin-top: 5px;">A02</div>
  </div>
</div>

<mat-divider></mat-divider>
<div style="margin-top: 20px;"></div>

<div *ngIf="is_show_container_A01" class="main-container">
  <div *ngFor="let image of A01_images;">
    <a href="{{image}}" target="_blank" style="margin-top: 5px; margin-right: 5px;">
      <img class="item-image" src="{{image}}" alt="">
    </a>
  </div>
</div>

<div *ngIf="is_show_container_A02" class="main-container">
  <div *ngFor="let image of A02_images;">
    <a href="{{image}}" target="_blank" style="margin-top: 5px; margin-right: 5px;">
      <img class="item-image" src="{{image}}" alt="">
    </a>
  </div>
</div>
