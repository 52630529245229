import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-page-navigator',
  templateUrl: './page-navigator.component.html',
  styleUrls: ['./page-navigator.component.css']
})
export class PageNavigatorComponent implements OnInit {

  pages:page[] = [
    {url:''},
    {url:'login'},
    {url:'scb-home345'},
    {url:'bai'},
    {url:'hidden'},
    {url:'etabs/shear-reinforcing'},
    {url:'temp-notes'},
    {url:'upload-images'},
    {url:'image-list'},
    {url:'encryption'},
  ];


  constructor() { }

  ngOnInit(): void {
  }

}

interface page {
  url: string | undefined
}
