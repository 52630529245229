import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Module
import { ScbHomeComponent } from './modules/scb-home/scb-home.component';
import { LoginComponent } from './modules/login/login.component';
import { BaiBookmarkComponent } from './modules/bai-bookmark/bai-bookmark.component';
import { HiddenComponent } from './pages/hidden/hidden.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { ShearReinforcingComponent } from './pages/shear-reinforcing/shear-reinforcing.component';
import { TempNotesComponent } from './pages/temp-notes/temp-notes.component';
import { PageNavigatorComponent } from './pages/page-navigator/page-navigator.component';
import { UploadImageComponent } from './pages/upload-image/upload-image.component';
import { ImageListComponent } from './pages/image-list/image-list.component';
import { EncryptionComponent } from './pages/encryption/encryption.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { HomeContainerComponent } from './pages/home-container/home-container.component';

const routes: Routes = [
  {path:'', redirectTo:'/main-menu', pathMatch:'full'},
  {path:'login', component: LoginComponent},
  {path:'scb-home345', component: ScbHomeComponent},
  {path:'bai', component: BaiBookmarkComponent},
  {path:'hidden', component:HiddenComponent, canActivate: [AuthGuard]},
  {path:'etabs/shear-reinforcing', component:ShearReinforcingComponent},
  {path:'temp-notes', component:TempNotesComponent},
  {path:'pages', component:PageNavigatorComponent},
  {path:'upload-images', component:UploadImageComponent},
  {path:'image-list', component:ImageListComponent},
  {path:'encryption', component:EncryptionComponent},
  {path:'main-menu', component:MainMenuComponent},
  {path:'home-container', component:HomeContainerComponent}
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }