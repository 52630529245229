import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, Form } from '@angular/forms';

@Component({
  selector: 'app-shear-reinforcing',
  templateUrl: './shear-reinforcing.component.html',
  styleUrls: ['./shear-reinforcing.component.css']
})
export class ShearReinforcingComponent implements OnInit {

  rebars:string[] = ['RB6', 'RB9', 'DB12'];
  num_rebar:number[] = [1, 2, 3];
  json_list:ShearReinforcing[] = [
      {
        "rebar": "RB6",
        "spacing": 100,
        "av_s": 0.565
      },
      {
        "rebar": "RB6",
        "spacing": 125,
        "av_s": 0.452
      },
      {
        "rebar": "RB6",
        "spacing": 150,
        "av_s": 0.377
      },
      {
        "rebar": "RB6",
        "spacing": 175,
        "av_s": 0.323
      },
      {
        "rebar": "RB6",
        "spacing": 200,
        "av_s": 0.283
      },
      {
        "rebar": "RB6",
        "spacing": 225,
        "av_s": 0.251
      },
      {
        "rebar": "RB6",
        "spacing": 250,
        "av_s": 0.226
      },
      {
        "rebar": "RB6",
        "spacing": 275,
        "av_s": 0.206
      },
      {
        "rebar": "RB6",
        "spacing": 300,
        "av_s": 0.188
      },
      {
        "rebar": "RB9",
        "spacing": 100,
        "av_s": 1.272
      },
      {
        "rebar": "RB9",
        "spacing": 125,
        "av_s": 1.018
      },
      {
        "rebar": "RB9",
        "spacing": 150,
        "av_s": 0.848
      },
      {
        "rebar": "RB9",
        "spacing": 175,
        "av_s": 0.727
      },
      {
        "rebar": "RB9",
        "spacing": 200,
        "av_s": 0.636
      },
      {
        "rebar": "RB9",
        "spacing": 225,
        "av_s": 0.565
      },
      {
        "rebar": "RB9",
        "spacing": 250,
        "av_s": 0.509
      },
      {
        "rebar": "RB9",
        "spacing": 275,
        "av_s": 0.463
      },
      {
        "rebar": "RB9",
        "spacing": 300,
        "av_s": 0.424
      },
      {
        "rebar": "DB12",
        "spacing": 100,
        "av_s": 2.262
      },
      {
        "rebar": "DB12",
        "spacing": 125,
        "av_s": 1.810
      },
      {
        "rebar": "DB12",
        "spacing": 150,
        "av_s": 1.508
      },
      {
        "rebar": "DB12",
        "spacing": 175,
        "av_s": 1.293
      },
      {
        "rebar": "DB12",
        "spacing": 200,
        "av_s": 1.131
      },
      {
        "rebar": "DB12",
        "spacing": 225,
        "av_s": 1.005
      },
      {
        "rebar": "DB12",
        "spacing": 250,
        "av_s": 0.905
      },
      {
        "rebar": "DB12",
        "spacing": 275,
        "av_s": 0.823
      },
      {
        "rebar": "DB12",
        "spacing": 300,
        "av_s": 0.754
      }
  ];

  rebarControl:FormControl = new FormControl('RB6');
  numControl:FormControl = new FormControl(1);
  requiredAvControl:FormControl = new FormControl(0, [Validators.required]);

  result:any = {};
  error_msg:string = "";

  constructor() {}

  ngOnInit(): void {
    this.rebarControl.valueChanges.subscribe(value => {
      this.updateResult();
    });
    this.numControl.valueChanges.subscribe(value => {
      this.updateResult();
    });
  }

  updateResult():void{
    let rebar:string = this.rebarControl.value;
    let num_rebar:number = this.numControl.value;
    let required_av_s:number = this.requiredAvControl.value;

    let arr_filter:ShearReinforcing[] = this.json_list.filter(element => element.rebar == rebar);
    let arr_mod:ShearReinforcing[] = arr_filter.map(element => ({
      rebar: element.rebar,
      spacing: element.spacing,
      av_s: element.av_s * num_rebar
    }));

    let arr_result = arr_mod.filter(element => element.av_s >= required_av_s);
    console.log(arr_result);

    if(arr_result.length > 0){
      this.result = arr_result.pop();
      this.error_msg = "";
    }else{
      this.result = {};
      this.error_msg = "Please increase bar size or consider to bundle. Minimum spacing (100mm.) for the current size is still not enough.";
    }
  }

}

export interface ShearReinforcing{
  rebar: string,
  spacing: number,
  av_s: number
}
