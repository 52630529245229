import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScbHomeComponent } from './modules/scb-home/scb-home.component';
import { LoginComponent } from './modules/login/login.component';
import { WalletCardComponent } from './shared/components/wallet-card/wallet-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';

import { DataTablesModule } from "angular-datatables";
import { BaiBookmarkComponent, PostDialog } from './modules/bai-bookmark/bai-bookmark.component';
import { CardComponent } from './modules/bai-bookmark/components/card/card.component';
import { LoginFormComponent } from './shared/components/login-form/login-form.component';
import { HiddenComponent } from './pages/hidden/hidden.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuard } from './shared/auth/auth.guard';
import { ShearReinforcingComponent } from './pages/shear-reinforcing/shear-reinforcing.component';
import { TempNotesComponent } from './pages/temp-notes/temp-notes.component';
import { UploadImageComponent } from './pages/upload-image/upload-image.component';
import { PageNavigatorComponent } from './pages/page-navigator/page-navigator.component';
import { ImageListComponent } from './pages/image-list/image-list.component';
import { EncryptionComponent } from './pages/encryption/encryption.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { HomeContainerComponent } from './pages/home-container/home-container.component';

@NgModule({
  declarations: [
    AppComponent,
    ScbHomeComponent,
    LoginComponent,
    WalletCardComponent,
    BaiBookmarkComponent,
    CardComponent,
    PostDialog,
    LoginFormComponent,
    HiddenComponent,
    RegisterComponent,
    ShearReinforcingComponent,
    TempNotesComponent,
    UploadImageComponent,
    PageNavigatorComponent,
    ImageListComponent,
    EncryptionComponent,
    MainMenuComponent,
    HomeContainerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    ReactiveFormsModule
  ],
  providers: [CookieService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
