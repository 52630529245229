<div class="flex justify-between">
    <h5 class="card-title">{{name}}</h5>
    <div style="color: rgb(89, 189, 114); font-size: 3.5mm;">{{bring_forward_balance | number:'1.0-0'}}</div>
</div>
<mat-accordion>
    <mat-expansion-panel (opened)="visibility = true"
                         (closed)="visibility = false">
        <mat-expansion-panel-header>
        <mat-panel-title>
            Balance
        </mat-panel-title>
        <mat-panel-description>
            {{balance}} บาท
        </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="submit(f.value)" #f="ngForm">
            <div>
                <mat-form-field>
                    <mat-label>จำนวนเงินฝาก</mat-label>
                    <input matInput placeholder="Ex. 15000" value="" name="amount" ngModel>
                </mat-form-field>
            </div>
            <button type="submit" class="btn btn-primary btn-sm">ฝากเงินเพิ่ม</button>
        </form>
    </mat-expansion-panel>
</mat-accordion>