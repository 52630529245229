import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

// jwt_decode
import jwt_decode  from "node_modules/jwt-decode";
import { DecodedToken } from 'src/app/shared/services/iToken';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  token_data:any;
  username:string = "";

  constructor(private authService:AuthService, private router:Router) { }

  ngOnInit(): void {
  }

  login(credentials:any):void{
    this.authService.login(JSON.stringify(credentials)).subscribe(
      (res) => {
        //console.log(res);
        localStorage.setItem('access_token', res.access);
        localStorage.setItem('refresh_token', res.refresh);

        this.token_data = localStorage.getItem('access_token');
        if(typeof(this.token_data) === "string"){
          //console.log(jwt_decode<DecodedToken>(this.token_data));
          this.username = jwt_decode<DecodedToken>(this.token_data).username;
          localStorage.setItem('username', this.username);
          this.router.navigate(['/bai']);
          //console.log(this.username);
        }else{
          console.log('Error: decoded token = null')
        }
      },
      error => console.log(error)
    )
    // console.log(credentials);
  }

}
