<div style="background-color:rgb(236, 236, 236); min-height: 100vh;">
    <mat-toolbar class="d-flex justify-content-between">
        <a routerLink="/bai" style="text-decoration: none; color: black;">
            <span>Bai Developer bookmark</span>
        </a>
        <div>
            <a routerLink="/login" mat-flat-button color="warn" *ngIf="!checkLogIn">Login</a>
            <a mat-flat-button (click)="logout()" *ngIf="checkLogIn" color="warn">Logout</a>
        </div>
    </mat-toolbar>
    
    
    <div> <!-- Add relative here to make button absนlute from the div not body -->
        <div style="position: absolute; bottom: 10px; right: 10px;">
            <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" (click)="openDialog()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-tab-group mat-align-tabs="start">
            <div *ngFor="let categoryObject of categoryObjects">
                <div *ngIf="categoryObject.category === 'Hidden';
                     then hidden 
                     else no_hidden"></div>
                <ng-template #hidden>
                    <div *ngIf="checkLogIn">
                        <mat-tab [label]="categoryObject.category">
                            <mat-expansion-panel hideToggle>
                                <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Hidden
                                </mat-panel-title>
                                <mat-panel-description>
                                    This is a summary of the content
                                </mat-panel-description>
                                </mat-expansion-panel-header>
                                    <div
                                        #other
                                        *ngFor="let tabObject of categoryObject.tab; let last = last"
                                        mat-subheader 
                                        class="m-3"
                                    >
                                        {{tabObject.name}}
                                        <div class="d-flex flex-wrap m-3">
                                            <a  *ngFor="let bookmark of tabObject.bai_bookmarks;" 
                                                [href]="bookmark.url" 
                                                style="text-decoration: none" 
                                                target="_blank"
                                                class="m-1"
                                            >
                                                <cpn-card 
                                                    [title]="bookmark.title"
                                                    [subtitle]="bookmark.subtitle"
                                                    [content]="bookmark.content"
                                                    [avatar]="bookmark.avatar"
                                                    [time]="bookmark.time"
                                                ></cpn-card>
                                            </a>
                                        </div>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </div>                                
                            </mat-expansion-panel>
                        </mat-tab>
                    </div>
                </ng-template>
                <ng-template #no_hidden>
                    <mat-tab [label]="categoryObject.category">
                        <div
                            #other
                            *ngFor="let tabObject of categoryObject.tab; let last = last"
                            mat-subheader 
                            class="m-3"
                        >
                            {{tabObject.name}}
                            <div class="d-flex flex-wrap m-3">
                                <a  *ngFor="let bookmark of tabObject.bai_bookmarks;" 
                                    [href]="bookmark.url" 
                                    style="text-decoration: none" 
                                    target="_blank"
                                    class="m-1"
                                >
                                    <cpn-card 
                                        [title]="bookmark.title"
                                        [subtitle]="bookmark.subtitle"
                                        [content]="bookmark.content"
                                        [avatar]="bookmark.avatar"
                                        [time]="bookmark.time"
                                    ></cpn-card>
                                </a>
                            </div>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </div>
                    </mat-tab>
                </ng-template>
            </div>
        </mat-tab-group>
    </div>
</div>

