import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'comp-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  loginForm = new FormGroup({});
  password_hide:boolean = true;
  @Output() form_submit = new EventEmitter<any>();
  
  constructor(private builder: FormBuilder) { }
  
  private buildForm() {
    this.loginForm = this.builder.group({
      username: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(6)
      ]]
    });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  login():void{
    if(this.loginForm.valid){
      this.form_submit.emit(this.loginForm.value);
    }else{
      console.log('Submited invalid form.')
    }
  }

}
