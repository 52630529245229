<div 
  style="
    flex-direction: column;
    padding: 10px;
  "
>
  <input type="text" [(ngModel)]="note_to_post" #user_input>
  <button 
    mat-raised-button 
    color="primary" 
    (click)="post(user_input.value)"
    style="margin-left: 10px;"
  >Post</button>
  
  <div *ngFor="let item of temp_notes; let i = index" style="margin-left: 10px; margin-top: 20px;">
    <span #unique_note>{{item.note}}</span>
    <button mat-icon-button color="accent" (click)="copyMessage(unique_note)">
      <mat-icon>open_in_new</mat-icon>
    </button>
  </div>
</div>