<div class="container">
  
  <a 
    *ngFor="let item of pages;"
    style="font-size: 2rem;"
    routerLink="/{{item.url}}" 
    routerLinkActive="active" 
    target="_blank" 
  >{{item.url}}</a>

</div>

