import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Balance, Payment } from './iPayment';

// environment
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentListService {

  private url:string = environment.api_host + "/singprasert/home-payment/?format=json";
  //private url:string = "https://jsonplaceholder.typicode.com/users";

  constructor(private http:HttpClient) { }

  getPaymentList():Observable<Payment[]>{
    return this.http.get<Payment[]>(this.url)
  }

  public getLoanAccountList():Observable<Balance[]>{
    let _url:string = environment.api_host + "/singprasert/loan-accounts/?format=json";
    return this.http.get<Balance[]>(_url);
  }

  public getBringForwardBalance():Observable<Balance[]>{
    let _url:string = environment.api_host + "/singprasert/bring-forward-balances/?format=json";
    return this.http.get<Balance[]>(_url);
  }
}
