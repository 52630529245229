import { Component, OnInit } from '@angular/core';
import { copyArr } from 'src/app/shared/functions/array';

import { TempNotesApiService } from 'src/app/shared/services/temp-notes-api.service';

@Component({
  selector: 'app-temp-notes',
  templateUrl: './temp-notes.component.html',
  styleUrls: ['./temp-notes.component.css']
})
export class TempNotesComponent implements OnInit {

  note_to_post:string = '';

  temp_notes:TempNote[] = [];

  constructor(private tempNotesApi:TempNotesApiService) { 
    this.updateNotes();
  }

  ngOnInit(): void {
    
  }

  copyMessage(inputElement:HTMLSpanElement){
    
    let note_text:string = inputElement.innerText;
    
    //---- copy to clipboard by create new temporary element and select copy
    let temp_element = document.createElement('textarea');
    temp_element.value = note_text;
    document.body.appendChild(temp_element);
    temp_element.select();
    document.execCommand('copy')  // .execCommand is obsoleted but can't find alternative one.
    document.body.removeChild(temp_element);
    //----
  }

  updateNotes():void{
    this.tempNotesApi.getTempNotes()
      .then(res => <TempNote[]>(res))
      .then(data => this.temp_notes = copyArr(data));
  }

  post(value:string):void{
    this.tempNotesApi.postTempNotes(value)
      .then(res => this.updateNotes())
      .catch(err => console.log(err));
  }

}


interface TempNote{
  created:string,
  note:string
}

