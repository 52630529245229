import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaiBookmark } from './iBaiBookmark';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaiBookmarkService {

  endpoint:string = environment.api_host + '/singprasert/bai-bookmark/?format=json';
  post_endpoint:string = environment.api_host + '/singprasert/bai-bookmark/';  // /(forward slash) at the end is so important as it could leads to CORS error without it.

  // Header for post request
  httpOptions:any = {};

  constructor(private http:HttpClient, private cookie_service:CookieService) { 
    // Initial csrf for post request
    let csrf = this.cookie_service.get("csrftoken");
    if (typeof(csrf) === 'undefined') {
      csrf = '';
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': csrf,
        'Content-Type': 'application/json'
      }),
    };
  }

  getBaiBookmark():Observable<BaiBookmark[]>{
    return this.http.get<BaiBookmark[]>(this.endpoint);
  }

  postBaiBookmark(data:any):Observable<any>{
    console.log(data);
    return this.http.post(this.post_endpoint, data, this.httpOptions);
  }
}
