import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ImageHostingService, ImageTag } from 'src/app/shared/services/image-hosting.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent implements OnInit {


  //-- Reactive form
  form_group = new FormGroup({
    third_party_url: new FormControl('')
  })
  //--

  //-- For easy call form in html template (For angular 13 up)
  get third_party_url() { return this.form_group.get('third_party_url'); };
  //--

  TAGS:ImageTag[] = [];
  tags_str:string[] = [];
  selectedTags:string[] = [];
  files!:FileList;

  constructor(private api:ImageHostingService) { }

  ngOnInit(): void {    
    this.api.getTags().subscribe(res => {
      this.TAGS = res;
      this.TAGS.forEach(e => {
        this.tags_str.push(e.name);
      })
    })
  }

  public async onSubmit(form_value:any):Promise<any>{
    // console.log(form_value);

    this.api.postBulkImages(this.selectedTags, form_value, this.files).subscribe(res => {
      console.log(res);
    })
    
  }

  public updateTag(status:boolean, tag:ImageTag){

    if(status){
      this.selectedTags.push(tag.name);
    }else{
      this.selectedTags = this.selectedTags.filter(e => e != tag.name);
    }
    
  }

  public handleFilesUpload(event_target:any){
    if(event_target){
      this.files = event_target.files;
      // console.log(this.files);
      return;
    }
  }

}
