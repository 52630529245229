import { Component, EventEmitter, Input, Output } from '@angular/core';

import { WalletBalanceService } from '../../services/wallet-balance.service';

@Component({
  selector: 'app-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.css']
})
export class WalletCardComponent{
  
  @Input() name:string = '';
  @Input() balance:number = 0;
  @Input() bring_forward_balance:number = 0;

  @Output() balanceChange = new EventEmitter();

  visibility:boolean = false;
  
  constructor(private wallet_service:WalletBalanceService) { 

  }
  
  submit(value:any){
    let data:any = {'name': this.name, 'amount': value.amount}
    
    this.wallet_service.postDeposit(data).subscribe(
      (res) => {
        console.log(res);
        this.balanceChange.emit();
      },
      error => console.log(error)
    )

  }

}
