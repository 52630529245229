<div class="flex mt-3">
  <mat-checkbox 
    *ngFor="let tag of TAGS"
    (change)="updateTag($event.checked, tag)"
    class="ms-2"
  >{{tag.name}}</mat-checkbox>
</div>

<div class="mt-3">
  <mat-form-field appearance="fill">
    <mat-label>tag_type</mat-label>
    <mat-select [(ngModel)]="selectedTagType" name="tag_type">
      <mat-option [value]="'tags_or'">tags_or</mat-option>
      <mat-option [value]="'tags_and'">tags_and</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div>Min score</div>
<div>
  <mat-slider 
    min="0" 
    max="5.5" 
    step="0.1" 
    style="width: 50%;"
    [(ngModel)]="minimum_score"
    thumbLabel
  >
  </mat-slider>
</div>
<div>Max score</div>
<div>
  <mat-slider 
    min="0" 
    max="5.5" 
    step="0.1" 
    style="width: 50%;"
    [(ngModel)]="maximum_score"
    thumbLabel
  >
  </mat-slider>
</div>

<button mat-flat-button (click)="onSearch()" style="margin-bottom: 10px;">Basic</button>
<div style="margin: 20px;">
  <mat-slide-toggle [(ngModel)]="is_modify_enable">Delete Toggle: {{is_modify_enable}}</mat-slide-toggle>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="is_loading"></mat-progress-bar>
<div style="margin-bottom: 20px;">
  <mat-divider></mat-divider>
</div>

<div style="display: flex; flex-direction: row; flex-wrap: wrap;">
  <div *ngFor="let image of showing_images;">
    <div 
      style="
        display:flex; 
        flex-direction: column; 
        width: 400px; 
        max-width: 90vw;
        margin-left: 2px;
      " 
    >
      <a href="{{image.url}}" target="_blank">
        <img  src="{{image.url}}" alt="" style="width: 400px; max-width: 90vw;">
      </a>
      
      <div
        *ngIf="is_modify_enable"
        style="display: flex; margin-top: 5px; padding: 5px;"
      >
        <mat-slider 
          min="0" 
          max="5.5" 
          step="0.1" 
          value="{{image.score}}" 
          style="width: 100%;" 
          (change)="onScoreSliderChange($event, image)"
        >
          <input matSliderThumb>
        </mat-slider>
        <div 
          style="
            background-color:powderblue; 
            width: 100px; 
            margin: auto; 
            text-align: center;
          "
        >{{image.score}}</div>
      </div>
      
      <div style="display: flex; margin-top: 5px; padding: 5px;">
        <button
          *ngIf="is_modify_enable"
          mat-raised-button color="basic"
          (click)="updateImageScore(image)"
          style="width: 100%;"
        >Update score</button>
        
        <button
          *ngIf="is_modify_enable"
          mat-raised-button color="warn"
          (click)="imageDelete(image.id)"
          style="width: 100px; margin-left: 10px;"
        >Del</button>
      </div>
      
    </div>
  </div>
</div>

<div *ngIf="length">
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChange($event)"
  ></mat-paginator>
</div>
