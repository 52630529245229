<div class="flex justify-content-center">
  <mat-card class="mt-3 mx-1" style="max-width: 500px;">
    <mat-card-content>
      <form>
        <mat-form-field style="width: 100%;">
      
          <mat-label>[Encryption Key]</mat-label>
          <textarea 
            matInput
            [formControl]="encryption_key_form" value="encryption_key_form.value"
            placeholder="Ex. iowefhr0-23-=="
            (keyup)="onMessageChange()"
          ></textarea>
      
        </mat-form-field>
      
        <mat-form-field style="width: 100%;">
      
          <mat-label>[Message]</mat-label>
          <textarea 
            matInput
            [formControl]="message_form" value="message_form.value"
            placeholder="Ex. Any message that you want to encrypt."
            (keyup)="onMessageChange()"
          ></textarea>
      
        </mat-form-field>
      
        <p class="b" style="width: 100%; word-wrap: break-word;">{{cypher}}</p>
        <button 
          mat-raised-button
          color="accent"
          (click)="copyEncryptCypher()"
        >Copy cypher to clipboard</button>
      
        <mat-form-field class="mt-4" style="width: 100%;">
      
          <mat-label>[Decryption Key]</mat-label>
          <textarea 
            matInput
            [formControl]="decryption_key_form" value="decryption_key_form.value"
            placeholder="Ex. iowefhr0-23-=="
            (keyup)="onCypherChange()"
          ></textarea>
      
        </mat-form-field>
      
        <mat-form-field style="width: 100%;">
      
          <mat-label>[Cypher]</mat-label>
          <textarea 
            matInput
            [formControl]="cypher_form" value="cypher_form.value"
            placeholder="Ex. Any cypher that you want to decrypt."
            (keyup)="onCypherChange()"
          ></textarea>
      
        </mat-form-field>
      
        <p class="b" style="width: 100%; word-wrap: break-word;">{{message}}</p>
        <button 
          mat-raised-button 
          color="accent" 
          (click)="copyDecryptedMessage()"
        >Copy msg to clipboard</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
