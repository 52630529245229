import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  login_endpoint:string = "";
  refresh_endpoint:string = "";

  token_data:any;
  username:string = "";

  // Header for post request
  httpOptions:any = {};

  constructor(private http:HttpClient) { 
    // Initial csrf for post request
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    this.login_endpoint = environment.login_endpoint;
    this.refresh_endpoint = environment.refresh_token_endpoint;
  }

  

  login(credentials:any):Observable<any>{
    return this.http.post(this.login_endpoint, credentials, this.httpOptions)
  }

  logout():void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
  }

  // Simple JWT for Django REST return { "access": "..." } if not expiration when send post request with { "refresh": "..."} 
  private _getTokenRefresh(refresh_token:any):Observable<any> {
    return this.http.post(this.refresh_endpoint, refresh_token, this.httpOptions);
      // return Observable that return {"access":"..."} if success.
  }

  // Check expiration of localStorage token and force logout if invalid.
  getTokenRefresh(){
    let refresh_object:any = {
      refresh: localStorage.getItem('refresh_token')
    }
    this._getTokenRefresh(refresh_object).subscribe(
      res => {
        // set new access token
        localStorage.setItem('access_token', res.access);
      },
      err => {
        // Force logout if token invalid or expired
        this.logout();
        console.log("Error: Refresh " + err.error.detail + "; Force logout.");
        console.log(err.error);
      }
    )
  }

  get isLoggedIn():boolean {
    return !!localStorage.getItem('access_token');
        // !! in front of localStorage makes its become boolean if token is exist.
  }
}
