<mat-toolbar>
  <span>SCB Home345</span>
</mat-toolbar>

<div class="container mt-3">
  <!-- <div>
    บัญชีบ้าน SCB = 045-529904-2
  </div> -->
  <div>
    บัญชีบ้าน MAKE App (บ้าน 345)
  </div>
  
  <div>
    เงินคงเหลือรวมในบัญชี = 
    <span class="" [ngClass]="{'text-success':bank_balance>40000, 'text-danger':bank_balance<=40000}">
      {{bank_balance}}
    </span>
    บาท
  </div>

  <div>
    ยอดกู้มาจากบัญชีอื่นๆ (เช่น พ่อ, แม่, หุ้น) =
    <span>
      {{loan_balance}}
    </span>
    บาท
  </div>

  <div class="d-flex flex-wrap justify-content-center mt-4">
      <div *ngFor="let owner of owners; index as i" class="m-2">
          <app-wallet-card [name]="owner.name" [balance]="owner.balance" [bring_forward_balance]="owner.bring_forward_balance" (balanceChange)="balanceChange()"></app-wallet-card>
      </div>
  </div>

  <div class="d-flex flex-wrap justify-content-center mt-5">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-hover small-text">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">งวด</th>
            <th scope="col">ค่าผ่อนบ้าน</th>
            <th scope="col">ค่าไฟ</th>
            <th scope="col">ค่าน้ำ</th>
            <th scope="col">ค่าเน็ต</th>
            <th scope="col">ค่าอื่นๆ</th>
            <th scope="col">รวม</th>
            <th scope="col">หารสาม</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment of payments; index as i">
            <td>
              {{payment.number}} 
              
            </td>
            <td>{{payment.date}}</td>
            <td>
              <div *ngIf="!payment.is_completed">{{payment.home_loan}}</div>
              <div *ngIf="payment.is_completed">
                <div>
                  <span style="text-decoration: line-through; color: rgb(163, 163, 163);">
                    {{payment.home_loan}}
                  </span>
                  <span 
                    class="material-symbols-outlined" 
                    style="font-size: 16px; color: rgb(134, 226, 134);"
                  >
                    price_check
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="!payment.is_electric_bill_paid">{{payment.electric}}</div>
              <div *ngIf="payment.is_electric_bill_paid">
                <div>
                  <span style="text-decoration: line-through; color: rgb(163, 163, 163);">
                    {{payment.electric}}
                  </span>
                  <span 
                    class="material-symbols-outlined" 
                    style="font-size: 16px; color: rgb(134, 226, 134);"
                  >
                    price_check
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="!payment.is_water_bill_paid">{{payment.water}}</div>
              <div *ngIf="payment.is_water_bill_paid">
                <div>
                  <span style="text-decoration: line-through; color: rgb(163, 163, 163);">
                    {{payment.water}}
                  </span>
                  <span 
                    class="material-symbols-outlined" 
                    style="font-size: 16px; color: rgb(134, 226, 134);"
                  >
                    price_check
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="!payment.is_internet_bill_paid">{{payment.internet}}</div>
              <div *ngIf="payment.is_internet_bill_paid">
                <div>
                  <span style="text-decoration: line-through; color: rgb(163, 163, 163);">
                    {{payment.internet}}
                  </span>
                  <span 
                    class="material-symbols-outlined" 
                    style="font-size: 16px; color: rgb(134, 226, 134);"
                  >
                    price_check
                  </span>
                </div>
              </div>
            </td>
            <td>
              <div *ngIf="!payment.is_other_bill_paid">{{payment.other}}</div>
              <div *ngIf="payment.is_other_bill_paid">
                <div>
                  <span style="text-decoration: line-through; color: rgb(163, 163, 163);">
                    {{payment.other}}
                  </span>
                  <span 
                    class="material-symbols-outlined" 
                    style="font-size: 16px; color: rgb(134, 226, 134);"
                  >
                    price_check
                  </span>
                </div>
              </div>
            </td>
            <td>{{payment.home_loan + payment.electric + payment.water + payment.internet + payment.other}}</td>
            <td class="text-primary bg-light">{{(payment.home_loan + payment.electric + payment.water + payment.internet + payment.other)/3 | number : '1.0-0'}}</td>
          </tr>
        </tbody>
      </table>
  </div>

    
  <div style="margin-top: 50px;">Note: 
    <span 
      class="material-symbols-outlined" 
      style="font-size: 16px; color: rgb(8, 177, 8);"
    >
      price_check
    </span>
     = ชำระเงินแล้ว
  </div>
</div>

