import { Component, OnInit } from '@angular/core';
import { 
  ImageHostingService,
  ImageTag 
} from 'src/app/shared/services/image-hosting.service';
import {PageEvent} from '@angular/material/paginator';

import { shuffleArr } from 'src/app/shared/functions/array';

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.css'],
})
export class ImageListComponent implements OnInit {

  TAGS:ImageTag[] = [];
  tags_str:string[] = [];
  selectedTags:string[] = [];
  selectedTagType:string = 'tags_or';

  images:ImageViewApiResponse[] = [];
  showing_images:ImageViewApiResponse[] = [];

  is_modify_enable:boolean = false;

  minimum_score:number = 5.0;
  maximum_score:number = 5.5;

  is_loading:boolean = false;

  // MatPaginator Inputs
  length!:number;
  pageSize = 10;
  pageSizeOptions:number[] = [5, 10, 20, 40];
  // MatPaginator Output
  pageEvent!:PageEvent;
  pageIndex = 0;

  constructor(private api:ImageHostingService) { }

  ngOnInit(): void {
    // this.api.getImageQuery().subscribe(res => {
    //   console.log(res);
      
    // })

    this.api.getTags().subscribe(res => {
      this.TAGS = res;
      this.TAGS.forEach(e => {
        this.tags_str.push(e.name);
      })
    })

  }

  public updateTag(status:boolean, tag:ImageTag){

    if(status){
      this.selectedTags.push(tag.name);
    }else{
      this.selectedTags = this.selectedTags.filter(e => e != tag.name);
    }
    
  }

  public async onSearch(){
    this.is_loading = true;

    let tag_query_str:string = '';
    this.selectedTags.forEach(e => {
      tag_query_str += `${e},`;
    });

    tag_query_str = tag_query_str.slice(0, -1);

    const result$ = this.api.getImageQuery(this.selectedTagType, tag_query_str);
    const result = await result$.toPromise().then(res => {
      let data = <ImageViewApiResponse[]>res;
      return data;
    });

    this.images = result.filter(e => e.score >= this.minimum_score).filter(e => e.score <= this.maximum_score);

    // Random shaffle the images
    this.images = shuffleArr(this.images);

    // this.images = result;
    // console.log(this.images)

    this.length = this.images.length;
    console.log(this.length)


    this.getShowingImages();

    this.is_loading = false;


    // this.api.getImageQuery(this.selectedTagType, tag_query_str)
    //   .subscribe(res => {
    //     let data = <ImageViewApiResponse[]>res;
    //     this.images = data;
    //   });

  }

  public async imageDelete(id:number):Promise<any>{
    if(this.is_modify_enable){
      const result = await this.api.deleteImage(id).toPromise();
      console.log(result);
      if(result.id){
        this.images = this.images.filter(e => e.id !== result.id);
      }
    }
  }

  public async updateImageScore(image:ImageViewApiResponse){
    const result$ = this.api.updateImageScore(image.id, image.score);
    const result = await result$.toPromise().catch(this.handleError);
    console.log(result);
  }

  public onScoreSliderChange(event:any, image:ImageViewApiResponse){
    image.score = event.value;
  }

  private handleError(){
    console.log('Error occur');
  }

  formatLabel(value:number) {
    return this.minimum_score;
  }

  onPageChange(event:any){
    this.pageEvent = event;
    this.pageIndex = this.pageEvent.pageIndex;
    this.pageSize = this.pageEvent.pageSize;
    
    // console.log(this.pageEvent);

    this.getShowingImages();
  }

  private getShowingImages(){
    let first_showing_image_index = this.pageSize * this.pageIndex;
    let last_showing_image_index = (this.pageSize * (this.pageIndex+1)) - 1 ;
    if(last_showing_image_index > this.images.length){
      last_showing_image_index = this.images.length - 1;
    }



    this.showing_images = [];
    this.images.forEach((e, i) => {
      if (i >= first_showing_image_index && i <= last_showing_image_index) {
        this.showing_images.push(e);
      }
    })

    // console.log(first_showing_image_index, last_showing_image_index);
  }

}

interface ImageViewApiResponse{
  id: number,
  third_party_url:string,
  tag: string[],
  url: string,
  score: number
}


