<div class="main-container">
  <div class="menu" (click)="redirectPage('/scb-home345')">
    <img class="menu-image" src="assets/image/menu1.png" alt="">
    <div style="text-align: center; margin-top: 5px;">บัญชีบ้าน 345</div>
  </div>
  <div class="menu" (click)="redirectPage('/home-container')">
    <img class="menu-image" src="https://home345.singprasertgroup.com/public/home345_containers/A01/cover.jpg" alt="">
    <div style="text-align: center; margin-top: 5px;">กล่องเก็บของ</div>
  </div>
</div>
