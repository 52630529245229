import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageHostingService {

  main_endpoint = environment.api_host;
  // main_endpoint = 'http://localhost:4200/local-dev-api';

  constructor(private http:HttpClient) { }

  /**
     * Description: Request api to get tag list.
     * @returns ex.
     *            [
                      {
                          "id": 1,
                          "name": "AP",
                          "category": "type"
                      },
                      {
                          "id": 2,
                          "name": "5.0",
                          "category": "score"
                      }
                  ]
  */
  public getTags():Observable<ImageTag[]>{
    const url:string = `${this.main_endpoint}/hosting/tag`;
    let header = new HttpHeaders({'content-type': 'application/json'});

    return this.http.get<ImageTag[]>(url, {headers:header})
  }

  public postBulkImages(tags:any, form:any, files:FileList){
    const url:string = `${this.main_endpoint}/hosting/bulk-images-upload`;

    const body = {
      'third_party_url': form.third_party_url, 
      'tags': tags, 
    }

    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });

    for (var i = 0; i < files.length; i++) {
      formData.append('url', files[i]);
    };

    return  this.http.post(url, formData)
  }


  public getImageQuery(tag_type:string, tag_list_str:string){
    const url:string = `${this.main_endpoint}/hosting/images`;
    let header = new HttpHeaders({'content-type': 'application/json'});

    let params!:HttpParams;
    if(tag_type=='tags_or'){
      params = new HttpParams()
        .set('tags_or', tag_list_str);
    }else{
      params = new HttpParams()
        .set('tags_and', tag_list_str);
    }

    return this.http.get(url, {headers:header, params:params})
  }

  public deleteImage(id:number):Observable<any>{
    const url:string = `${this.main_endpoint}/hosting/image/${id}/`;

    return this.http.delete(url);
  }

  public updateImageScore(id:number, score:number):Observable<any>{
    const url:string = `${this.main_endpoint}/hosting/image/${id}/`;

    return this.http.patch(url, {'score': score})
  }

}

export interface ImageTag{
  id: number,
  name: string,
  category: string
}
