import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WalletDeposit, Balance } from './iWalletDeposit';

import { environment } from 'src/environments/environment';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class WalletBalanceService {

  private url:string = environment.api_host + "/singprasert/home-wallet/?format=json";
  private balance_url:string = environment.api_host + "/singprasert/home-wallet/";

  // Header for post request
  httpOptions:any = {};
  
  constructor(private http:HttpClient, private cookieService:CookieService) {
    
    // Initial csrf for post request
    let csrf = this.cookieService.get("csrftoken");
    if (typeof(csrf) === 'undefined') {
      csrf = '';
    }
    this.httpOptions = {
      headers: new HttpHeaders({
        'X-CSRFToken': csrf,
        'Content-Type': 'application/json'
      }),
    };
  }

  getDepositList():Observable<WalletDeposit[]>{
    return this.http.get<WalletDeposit[]>(this.url)
  }

  getBalance(name:string):Observable<Balance>{
    return this.http.get<Balance>(this.balance_url+name)
  }

  postDeposit(data:any):Observable<any>{
    return this.http.post(this.balance_url, data, this.httpOptions)
  }

}
