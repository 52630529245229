<form class="container p-5" style="max-width: 600px;">

  <h2>Shear Reinforcing App.</h2>
  <mat-divider></mat-divider>
  <small style="color: rgb(131, 131, 131);">Spacing range is in 100 mm. and 300 mm.</small>

  <mat-form-field class="example-full-width mt-5" appearance="fill">
    <mat-label>Required Av/s (in mm.)</mat-label>
    <input matInput [formControl]="requiredAvControl" value="requiredAvControl.value" (keyup.enter)="updateResult()">
  </mat-form-field>

  <mat-form-field class="w30" appearance="fill">
    <mat-label>No.</mat-label>
    <mat-select [formControl]="numControl">
      <mat-option *ngFor="let num of num_rebar" [value]="num">{{num}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="w70 ps-1" appearance="fill">
    <mat-label>Rebar</mat-label>
    <mat-select [formControl]="rebarControl">
      <mat-option *ngFor="let rebar of rebars" [value]="rebar">{{rebar}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="accent" (click)="updateResult()">Get result</button>

  <div *ngIf="result.rebar" style="color: rgb(204, 118, 165);">
      <div class="mt-5">
        {{numControl.value}}-{{rebarControl.value}}@{{result.spacing}}
      </div>
      <div>
        Provided Av/s = {{result.av_s}}
      </div>
  </div>
  <div *ngIf="error_msg" class="mt-5" style="color: red;">
    {{error_msg}}
  </div>
</form>