import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CheckedUrl } from './iCheckedUrl';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PingUrlService {

  api_endpoint:string = environment.api_host + '/singprasert/check-url-status/'

  constructor(private http:HttpClient) { }

  getPing(url:string, domain:string):Observable<CheckedUrl>{
    let checking_endpoint:string = this.api_endpoint + "?url=" + url + "&domain=" + domain;
    return this.http.get<CheckedUrl>(checking_endpoint)
  }
}
