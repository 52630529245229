import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cpn-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() title:string = "";
  @Input() subtitle:string = "";
  @Input() content:string = "";
  @Input() avatar:string = "";
  @Input() time:string = "None";

  avatar_url:string = "url('https://material.angular.io/assets/img/examples/shiba1.jpg')";

  constructor() { }

  ngOnInit(): void {
    // If input avatar is empty use shiba avatar instead
    if(this.avatar !== ""){
      this.avatar_url = 'url(' + this.avatar + ')';
    }
  }

}
