import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import {Clipboard} from '@angular/cdk/clipboard';

import * as CryptoJS from 'crypto-js'; // For doc, https://cryptojs.gitbook.io/docs/

@Component({
  selector: 'app-encryption',
  templateUrl: './encryption.component.html',
  styleUrls: ['./encryption.component.css']
})
export class EncryptionComponent implements OnInit {

  // Form Control
  public message_form:FormControl = new FormControl();
  public encryption_key_form:FormControl = new FormControl();
  public cypher_form:FormControl = new FormControl();
  public decryption_key_form:FormControl = new FormControl();
  
  public cypher:string = '';
  public message:string = '';

  constructor(private clipboard: Clipboard) { }

  ngOnInit(): void {
  }

  encrypt(msg:string, encrypt_key:string):string{
    return CryptoJS.AES.encrypt(msg, encrypt_key).toString();
  }

  decrypt(cypher:string, decrypt_key:string):string{
    return CryptoJS.AES.decrypt(cypher, decrypt_key).toString(CryptoJS.enc.Utf8);
  }

  onMessageChange(){
    if(this.message_form.value && this.encryption_key_form.value){
      this.cypher = this.encrypt(this.message_form.value, this.encryption_key_form.value);
    }
  }

  onCypherChange(){
    if(this.cypher_form.value && this.decryption_key_form.value){
      this.message = this.decrypt(this.cypher_form.value, this.decryption_key_form.value);
    }
  }

  copyEncryptCypher() {
    this.clipboard.copy(this.cypher);
  }

  copyDecryptedMessage() {
    this.clipboard.copy(this.message);
  }

  log(value:any){
    console.log(value);
  }

  // private _Trim(str:string):string{
  //   return str.replace(/\s/g, "");
  // }

}
