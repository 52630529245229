<div class="container">

  <mat-card>

    <div class="card-container">

      <div class="my-2">
        <mat-checkbox 
          *ngFor="let tag of TAGS"
          (change)="updateTag($event.checked, tag)" 
        >{{tag.name}}</mat-checkbox>
      </div>
    
      <mat-divider></mat-divider>
    
      <form [formGroup]="form_group" 
            (ngSubmit)="onSubmit(this.form_group.value)"
      >
    
        <mat-form-field class="example-full-width my-2" appearance="fill">
          <mat-label>3rd Party Url</mat-label>
          <input matInput placeholder="" value="" formControlName="third_party_url">
        </mat-form-field>

        <mat-divider></mat-divider>
        
        <input type="file" multiple style="display: block;"
               class="my-2"
               (change)="handleFilesUpload($event.target)"
        >

        <mat-divider></mat-divider>

        <button mat-raised-button
                type="submit"
                class="my-2"
        >Submit</button>
    
      </form>



    </div>

  </mat-card>


</div>

