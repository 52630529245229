import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TempNotesApiService {

  endpoint:string = environment.api_host + '/singprasert/temp-notes/?format=json';
  post_endpoint:string = environment.api_host + '/singprasert/temp-notes/';

  // Header for post request
  httpOptions:any = {};

  constructor(private http:HttpClient) { 
    
    //-- Set headers for post request
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //--

  }

  getTempNotes():Promise<any>{
    return this.http.get(this.endpoint).toPromise();
  }

  postTempNotes(value:string):Promise<any>{
    let body = {
      'note':value
    }
    return this.http.post(this.post_endpoint, body, this.httpOptions).toPromise();
  }
}
