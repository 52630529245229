<mat-card class="login-card d-flex justify-content-center">
    <form [formGroup]="loginForm" (submit)="login()" class="form-group">
        <div class="d-flex flex-column align-items-center">
            
            <mat-form-field appearance="legacy" class="mat-form-field">
                <mat-label>Enter your username</mat-label>
                <input matInput type="text" name="username" placeholder="Username" formControlName="username">
                <mat-error *ngIf="loginForm.controls.username.hasError('required')">
                    Username is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        
            <mat-form-field appearance="legacy" class="mat-form-field mt-2">
                <mat-label>Enter your password</mat-label>
                <input matInput [type]="password_hide ? 'password' : 'text'" name="password" placeholder="Password" formControlName="password">
                <button mat-icon-button matSuffix type="button" (click)="password_hide = !password_hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="password_hide">
                    <mat-icon>{{password_hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="loginForm.controls.password.hasError('required')">
                    Password is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="loginForm.controls.password.errors?.minlength">
                    Password is <strong>required at least 6 characters</strong>
                </mat-error>
            </mat-form-field>
            
            <div class="mt-3 login-button">
                <button mat-raised-button type="submit" [disabled]="!loginForm.valid" class="me-3 login-button">Login</button>
            </div>
    
            <div class="my-3">
                <a href="" class="mx-2">Forgot password?</a>
                <a href="" class="mx-2">Register</a>
            </div>
        </div>
    </form>
</mat-card>
