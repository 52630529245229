import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-container',
  templateUrl: './home-container.component.html',
  styleUrls: ['./home-container.component.css']
})
export class HomeContainerComponent implements OnInit {

  is_show_container_A01:boolean = false;
  is_show_container_A02:boolean = false;

  A01_images:string[] = [
    'https://home345.singprasertgroup.com/public/home345_containers/A01/1.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/2.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/3.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/4.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/5.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/6.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/7.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/8.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/9.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/10.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/11.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/12.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/13.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/14.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/15.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/16.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/17.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/18.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/19.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/20.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/21.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A01/22.jpg'
  ];

  A02_images:string[] = [
    'https://home345.singprasertgroup.com/public/home345_containers/A02/1.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/2.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/3.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/4.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/5.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/6.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/7.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/8.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/9.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/10.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/11.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/12.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/13.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/14.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/15.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/16.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/17.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/18.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/19.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/20.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/21.jpg',
    'https://home345.singprasertgroup.com/public/home345_containers/A02/22.jpg'
  ];

  constructor() { }

  ngOnInit(): void {
  }

  private setShowToFalseAll():void{
    this.is_show_container_A01 = false;
    this.is_show_container_A02 = false;
  }

  showItem(container_name:string){
    this.setShowToFalseAll();
    if (container_name == 'A01') this.is_show_container_A01 = true;
    if (container_name == 'A02') this.is_show_container_A02 = true;
  }

}
