<h2 mat-dialog-title>Add bookmark</h2>
<mat-dialog-content class="mat-typography">
  <form class="input-form">
    <mat-form-field class="input-full-width">
        <mat-label>Category</mat-label>
        <input #category type="text" matInput [formControl]="categoryFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="categoryFormControl.hasError('required')">Category is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Tab</mat-label>
        <input #tab type="text" matInput [formControl]="tabFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="tabFormControl.hasError('required')">Tab is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Title</mat-label>
        <input #title type="text" matInput [formControl]="titleFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="titleFormControl.hasError('required')">Title is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Sub Title</mat-label>
        <input #subtitle type="text" matInput [formControl]="subtitleFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="subtitleFormControl.hasError('required')">Sub Title is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Content</mat-label>
        <input #content type="text" matInput [formControl]="contentFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="contentFormControl.hasError('required')">Content is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Domain</mat-label>
        <input #domain type="text" matInput [formControl]="domainFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="domainFormControl.hasError('required')">Domain is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Url</mat-label>
        <input #url type="text" matInput [formControl]="urlFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="urlFormControl.hasError('required')">Url is required.</mat-error>
    </mat-form-field>
    <mat-form-field class="input-full-width">
        <mat-label>Avatar</mat-label>
        <input #avatar type="text" matInput [formControl]="avatarFormControl" placeholder="">
        <mat-hint></mat-hint>
        <mat-error *ngIf="avatarFormControl.hasError('required')">Avatar is required.</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-button 
          [mat-dialog-close]="{
              category: category.value, 
              tab: tab.value,
              title: title.value,
              subtitle: subtitle.value,
              content: content.value,
              domain: domain.value,
              url: url.value,
              time: 'None',
              avatar: avatar.value
          }" 
          cdkFocusInitial>Save</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>