import { Component, OnInit } from '@angular/core';

import { PingUrlService } from 'src/app/shared/services/ping-url.service';
import { BaiBookmarkService } from 'src/app/shared/services/bai-bookmark.service';
import { BaiBookmark, CategoryTab } from 'src/app/shared/services/iBaiBookmark';
import { AuthService } from 'src/app/shared/services/auth.service';

// Material
import { MatDialog } from '@angular/material/dialog';

import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-bai-bookmark',
  templateUrl: './bai-bookmark.component.html',
  styleUrls: ['./bai-bookmark.component.css']
})
export class BaiBookmarkComponent implements OnInit {
  bookmarks:BaiBookmark[] = [];
  categoryObjects:CategoryTab[] = [];
  
  angular_bookmarks:BaiBookmark[] = [];
  devWork_bookmarks:BaiBookmark[] =[];
  other_bookmarks:BaiBookmark[] = [];

  constructor(
    private pingService:PingUrlService, 
    private baiBookmarkService:BaiBookmarkService,
    public dialog: MatDialog,
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    
    this.getAllBookmark();

    //Check refresh expiration in localStorage
    if(!!localStorage.getItem('access_token')){
      this.getTokenRefresh();
    }else{
      console.log('msg: No token is stored in local storage.')
    }
  }

  getAllBookmark():void {
    // get Bai bookmark from REST
    this.baiBookmarkService.getBaiBookmark().subscribe(res => {
      this.bookmarks = res;

      // Loop to get ping and collects each category
      this.bookmarks.forEach((bookmark, index) => {
        // get ping time for each bookmark
        this.pingService.getPing(bookmark.url, bookmark.domain).subscribe(res => {
          bookmark.time = res.time;
          // if(bookmark.category === 'Angular'){
          //   this.angular_bookmarks.push(bookmark);
          // }else if(bookmark.category === 'Dev-Work'){
          //   this.devWork_bookmarks.push(bookmark);
          // }else{
          //   this.other_bookmarks.push(bookmark);
          // }
        })

        // collect category
        const isCateDuplicated = this.categoryObjects.find(({category})=> category === bookmark.category);
        if(!isCateDuplicated) {
          this.categoryObjects.push(
            { 
              category: bookmark.category,
              tab: []
            }
          );
        }
        // collect tab
        this.categoryObjects.forEach((object, index) => {
          if(object.category === bookmark.category){
            const isTabDuplicated = object.tab.find(({name})=> name === bookmark.tab)
            if(!isTabDuplicated){
              object.tab.push({
                name: bookmark.tab,
                bai_bookmarks: []
              })
            }
          }
        })
        // Add bookmark in each tab
        this.categoryObjects.forEach((object, index) => {
          if(object.category === bookmark.category){
            object.tab.forEach((value)=>{
              if(value.name === bookmark.tab) value.bai_bookmarks.push(bookmark)
            })
          }
        })
      })

      // console.log(this.bookmarks);
      // console.log(this.categoryObjects);
    })
  }

  openDialog() {
    const dialogRef = this.dialog.open(PostDialog);

    dialogRef.afterClosed().subscribe(data => {
      this.baiBookmarkService.postBaiBookmark(JSON.stringify(data)).subscribe(
        res => console.log(res),
        error => console.log(error)
      );
    });
  }

  get checkLogIn():boolean{
    return this.authService.isLoggedIn
  }

  logout():void {
    this.authService.logout();
  }

  getTokenRefresh(){
    this.authService.getTokenRefresh()
  }

  debug(){
    console.log('Hello');
  }
}

// Don't forget to add PostDialog to declarations[] in app.module same as component
@Component({
  selector: 'post-dialog-element',
  templateUrl: 'post-dialog-element.html',
  styleUrls: ['./bai-bookmark.component.css']
})
export class PostDialog {
  categoryFormControl = new FormControl('', Validators.required);
  tabFormControl = new FormControl('', Validators.required);
  titleFormControl = new FormControl('', Validators.required);
  subtitleFormControl = new FormControl('', Validators.required);
  contentFormControl = new FormControl('', Validators.required);
  domainFormControl = new FormControl('', Validators.required);
  urlFormControl = new FormControl('', Validators.required);
  avatarFormControl = new FormControl('', Validators.required);
}
