import { Component, OnInit, OnDestroy } from '@angular/core';
import { Balance, Payment } from 'src/app/shared/services/iPayment';
import { PaymentListService } from 'src/app/shared/services/payment-list.service';
import { WalletBalanceService } from 'src/app/shared/services/wallet-balance.service';

// Data Table
import { Subject } from 'rxjs';

@Component({
  selector: 'app-scb-home',
  templateUrl: './scb-home.component.html',
  styleUrls: ['./scb-home.component.css']
})
export class ScbHomeComponent implements OnInit, OnDestroy{

  public owners:any[] = [
    {name: 'Khuntan', balance: 0, bring_forward_balance: 0},
    {name: 'Khunbai', balance: 0, bring_forward_balance: 0},
    {name: 'Khunone', balance: 0, bring_forward_balance: 0}
  ];

  // Data Table
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  initial_show_entries: number = 10;

  payments:Payment[] = [];

  bank_balance:number = 0;

  loan_balance:number = 0;

  sum_divided:number = 0;

  constructor(
    private service:PaymentListService,
    private wallet_service:WalletBalanceService
  ){ }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.initial_show_entries,
      retrieve: true,
      order:[[0, 'desc']]   // '0' is the table column(1st column) and 'desc' is the sorting order
    };
    this.service.getPaymentList().subscribe((res)=>{
      this.payments = res;
      // Calling the DT trigger to manually render the table
      this.dtTrigger.next();
      console.log(this.payments);

      let sum:number = 0;
      this.payments.forEach(e => {
        if(e.is_completed){
          sum += e.home_loan + e.electric + e.internet + e.water + e.other;
        }
      })
      this.sum_divided = sum/this.owners.length;
      this.owners.forEach(e => {
        e.bring_forward_balance += this.sum_divided;
      })
    })

    this.updateBalance();
    this._getLoanBalance();
    this._getBringForwardBalance();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  private _getBringForwardBalance():void{
    this.service.getBringForwardBalance().subscribe(res => {
      res.forEach((value, index) => {
        this.owners[index].bring_forward_balance += value.amount;
      })
    })
  }

  private _getLoanBalance():void{
    let _accounts:Balance[];
    let _total_loan_balance:number = 0;

    this.service.getLoanAccountList().subscribe(res => {
      _accounts = res;
      _accounts.forEach(e => {
        _total_loan_balance += e.amount;
      });
      
      this.loan_balance = _total_loan_balance;
      this.bank_balance += this.loan_balance;
    })
  }

  balanceChange():void{
    this.updateBalance();
  }
  
  private updateBalance():void{
    this.bank_balance = 0;

    this.wallet_service.getBalance('Khuntan').subscribe((res)=>{
      this.owners[0].balance = res.amount__sum;
      this.bank_balance += res.amount__sum;
      //console.log(this.owners[0]);
    })
    this.wallet_service.getBalance('Khunbai').subscribe((res)=>{
      this.owners[1].balance = res.amount__sum
      this.bank_balance += res.amount__sum;
      //console.log(this.owners[1]);
    })
    this.wallet_service.getBalance('Khunone').subscribe((res)=>{
      this.owners[2].balance = res.amount__sum
      this.bank_balance += res.amount__sum;
      //console.log(this.owners[2]);
    })
  }

  public getPaymentStatus(is_paid:boolean):string{
    if(is_paid){
      return 'จ่ายแล้ว'
    }

    return '-'
  }

}
